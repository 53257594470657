<div id="layout-wrapper">
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="index.html" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="20" />
            </span>
          </a>

          <a href="index.html" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="20" />
            </span>
          </a>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item waves-effect"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              class="rounded-circle header-profile-user"
              src="assets/images/Emp.png"
              alt="Header Avatar"
            />
            <span
              class="d-none d-xl-inline-block ms-1 fw-medium font-size-15"
              >{{ name }}</span
            >
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <!-- item-->
            <a class="dropdown-item" (click)="login()"
              ><i
                class="fa fa-sign-out font-size-14 align-middle me-1 text-muted"
              ></i>
              <span class="align-middle pointer">Sign out</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <a class="logo logo-dark">
        <span class="logo-sm">
          <img
            src="assets/images/haldi_ram.png"
            alt=""
            height="70%"
            width="75%"
            class="mt-2 m-lg-3"
          />
        </span>
        <span class="logo-lg">
          <img
            src="assets/images/haldi_ram.png"
            alt=""
            height="70%"
            width="75%"
            class="mt-2 m-lg-3"
          />
        </span>
      </a>

      <a class="logo logo-light">
        <span class="logo-sm">
          <img
            src="assets/images/haldi_ram.png"
            alt=""
            height="70%"
            width="75%"
            class="mt-2 m-lg-3"
          />
        </span>
        <span class="logo-lg">
          <img
            src="assets/images/haldi_ram.png"
            alt=""
            height="70%"
            width="75%"
            class="mt-2 m-lg-3"
          />
        </span>
      </a>
    </div>

    <button
      type="button"
      class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
    >
      <i class="fa fa-fw fa-bars"></i>
    </button>

    <div data-simplebar class="sidebar-menu-scroll mar_t">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <li class="menu-title">Menu</li>

          <li>
            <a class="pointer" (click)="dashboard()">
              <i class="uil-home-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <li>
            <a class="pointer" (click)="order()">
              <i class="uil-shopping-cart-alt"></i>
              <span>Orders</span>
            </a>
          </li>
          <li>
            <a class="pointer" (click)="cOrder()">
              <i class="uil-cancel"></i>
              <span>Cancel Orders</span>
            </a>
          </li>
          <li>
            <a class="pointer" (click)="invoice()">
              <i class="uil-invoice"></i>
              <span>Invoice</span>
            </a>
          </li>
          <li>
            <a class="pointer" (click)="reports()">
              <i class="uil-folder"></i>
              <span>Report</span>
            </a>
          </li>
          <!-- <li>
            <a class="pointer" (click)="customerPrice()">
              <i class="uil-dollar-sign"></i>
              <span>Customer Price</span>
            </a>
          </li> -->
          <!-- <li>
            <a class="pointer" (click)="customerDiscount()">
              <i class="uil-moneybag"></i>
              <span>Customer Discount</span>
            </a>
          </li> -->
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
  <!-- Left Sidebar End -->
</div>
