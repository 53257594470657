import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coffee-details',
  templateUrl: './coffee-details.component.html',
  styleUrls: ['./coffee-details.component.css']
})
export class CoffeeDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
