<div class="auth">
<div class="account-pages">
  <div class="container">
    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <a href="index.html" class="mb-5 d-block auth-logo">
            <img
              src="assets/images/logo-dark.png"
              alt=""
              height="22"
              class="logo logo-dark"
            />
            <img
              src="assets/images/logo-light.png"
              alt=""
              height="22"
              class="logo logo-light"
            />
          </a>
        </div>
      </div>
    </div> -->
    <div class="row align-items-center justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5 mt-3">
        <div class="card my-4">
          <div class="card-body p-4">
            <div class="text-center mt-2">
              <h5 class="text-primary">Welcome Back !</h5>
              <p class="text-muted">Sign in to continue to Haldiram.</p>
            </div>
            <div class="p-2 mt-4">
              <form [formGroup]="myForm" (ngSubmit)="dashboard()">
                <div class="form-group mb-3">
                  <label class="form-label" for="username">Mobile Number</label>
                  <input
                    type="number"
                    class="form-control"
                    id="username"
                    formControlName="username"
                    placeholder="Enter Mobile Number"
                  />
                  <div *ngIf="f.username.invalid && f.username.touched">
                    <div
                      *ngIf="f.username.errors?.required"
                      class="text text-danger"
                    >
                    Mobile Number required
                    </div>
                  </div>
                </div>

                <div class="form-group mb-3">
                 
                  <label class="form-label" for="userpassword">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    id="userpassword"
                    placeholder="Enter password"
                  />
                  <div *ngIf="f.password.invalid && f.password.touched">
                    <div
                      *ngIf="f.password.errors?.required"
                      class="text text-danger"
                    >
                      Password required
                    </div>
                  </div>
                </div>

                <!-- <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="auth-remember-check"
                  />
                  <label class="form-check-label" for="auth-remember-check"
                    >Remember me</label
                  >
                </div> -->

                <div class="mt-4 text-end">
                  <button
                    class="btn btn-primary w-sm waves-effect waves-light"
                    type="submit"
                    style="width: 100%"
                    [disabled]="!myForm.valid"
                  >
                    Sign In
                  </button>
                </div>

                <div class="mt-4 mb-4 text-center">
                  <p class="text-muted mb-2">Powered By</p>
                  <img
                    src="assets/images/logo.png"
                    alt=""
                    width="120px"
                    height="35px"
                    class="logo"
                  />
                  <!-- <div class="signin-other-title">
                    <h5 class="font-size-14 mb-3 title">Sign in with</h5>
                  </div>

                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a
                        href="javascript:void()"
                        class="
                          social-list-item
                          bg-primary
                          text-white
                          border-primary
                        "
                      >
                        <i class="mdi mdi-facebook"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void()"
                        class="social-list-item bg-info text-white border-info"
                      >
                        <i class="mdi mdi-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void()"
                        class="
                          social-list-item
                          bg-danger
                          text-white
                          border-danger
                        "
                      >
                        <i class="mdi mdi-google"></i>
                      </a>
                    </li>
                  </ul> -->
                </div>

                <div class="mt-4 text-center">
                  <p class="mb-0">
                    Don't have an account ?
                    <a class="fw-medium text-primary" (click)="register()" style="cursor:pointer">
                      Signup now
                    </a>
                  </p>
                </div>
                <div class="mt-4 text-center">
                  <p class="mb-0">
                    <a href="assets/hal/Welcome.html" target="_blank" class="fw-medium text-primary" style="cursor:pointer">
                     User Manual
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>

   
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
</div>