<div id="layout-wrapper">
  <app-header></app-header>
  <!-- ========== Left Sidebar Start ========== -->
  <app-sidebar></app-sidebar>
  <!-- Left Sidebar End -->

  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <!-- start page title -->
        <div class="row">
          <div class="col-12">
            <div
              class="page-title-box d-flex align-items-center justify-content-between"
            >
              <h4 class="mb-0 cursor-d">Dashboard</h4>

              <div class="page-title-right">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item active cursor-d">Dashboard</li>
                  <!-- <li class="breadcrumb-item">
                    <a>Haldiram</a>
                  </li> -->
                </ol>
              </div>
            </div>
          </div>
        </div>
        <!-- end page title -->
        <br />
        <div class="row" *ngIf="counts">
          <div class="col-md-6 col-xl-4">
            <div class="card">
              <div class="card-body p-3">
                <div>
                  <div class="row justify-content-center">
                    <div class="col-sm-12 d-flex justify-content-between">
                      <div class="col-sm-2 d-flex align-items-center">
                        <i
                          class="fa fa-cart-plus icon"
                          style="font-size: 35px; color: rgb(117, 160, 240)"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="col-sm-10 align-text-end">
                        <span
                          class="total-sty color-black"
                          style="color: #000000a1"
                          >{{ counts["total"] ? counts["total"] : 0 }}</span
                        >
                        <br />
                        <p
                          class="mb-2 color-black cursor-d"
                          style="color: #000000a1"
                        >
                          Total Orders
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row me-1 justify-content-between"></div>
                </div>
                <!-- <p class="text-muted mt-3 mb-0">
                  <span class="text-success me-1"
                    ><i class="mdi mdi-arrow-up-bold me-1"></i>2.65%</span
                  >
                  since last week
                </p> -->
              </div>
            </div>
          </div>
          <!-- end col-->
          <div class="col-md-6 col-xl-4">
            <div class="card">
              <div class="card-body p-3">
                <div>
                  <div class="row justify-content-center">
                    <div class="col-sm-12 d-flex justify-content-between">
                      <div class="col-sm-2 d-flex align-items-center">
                        <i
                          class="fa fa-inbox icon"
                          style="font-size: 35px; color: rgb(240, 126, 112)"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="col-sm-10 align-text-end">
                        <span
                          class="total-sty color-black"
                          style="color: #000000a1"
                          >{{ counts.pending ? counts.pending : 0 }}</span
                        >
                        <br />
                        <p
                          class="mb-2 color-black cursor-d"
                          style="color: #000000a1"
                        >
                          Pending Orders
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col-->
          <div class="col-md-6 col-xl-4">
            <div class="card">
              <div class="card-body p-3">
                <div>
                  <div class="row justify-content-center">
                    <div class="col-sm-12 d-flex justify-content-between">
                      <div class="col-sm-2 d-flex align-items-center">
                        <i
                          class="fa fa-window-close icon"
                          style="font-size: 35px; color: rgb(252, 190, 27)"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="col-sm-10 align-text-end">
                        <span
                          class="total-sty color-black"
                          style="color: #000000a1"
                          >{{ counts.cancelled ? counts.cancelled : 0 }}</span
                        >
                        <br />
                        <p
                          class="mb-2 color-black cursor-d"
                          style="color: #000000a1"
                        >
                          Cancel Orders
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end col-->
          <!-- <div class="col-md-6 col-xl-3" *ngIf="customerCounts">
            <div class="card">
              <div class="card-body p-3">
                <div>
                  <div class="row justify-content-center">
                    <div class="col-sm-12 d-flex justify-content-between">
                      <div class="col-sm-2 d-flex align-items-center">
                        <i
                          class="fa fa-clipboard icon"
                          style="font-size: 35px; color: rgb(161, 214, 92)"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="col-sm-10 align-text-end">
                        <span
                          class="total-sty color-black"
                          style="color: #000000a1"
                          >{{
                            customerCounts.credit ? customerCounts.credit : 0
                          }}</span
                        >
                        <br />
                        <p
                          class="mb-2 color-black cursor-d"
                          style="color: #000000a1"
                        >
                          Credit Balance
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- end row-->

        <!-- <div class="row" *ngIf="customerCounts">
          <div class="col-md-6 col-xl-6">
            <div class="card">
              <div class="card-body p-3">
                <div>
                  <div class="row justify-content-center">
                    <div class="col-sm-12 d-flex justify-content-between">
                      <div class="col-sm-5 d-flex align-items-center">
                        <div class="mr-1">
                          <i
                            class="fa fa-shopping-cart icon"
                            style="font-size: 35px; color: rgb(247 160 71 / 94%)"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div>
                          <p
                            class="mb-1 color-white cursor-d"
                            style="color: #000000a1; font-size: 17px;"
                          >
                            Total Balance
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-7 align-text-end">
                        <span class="total-sty" style="color: #000000a1">{{
                          customerCounts.total ? customerCounts.total : 0
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-6">
            <div class="card">
              <div class="card-body p-3">
                <div>
                  <div class="row justify-content-center">
                    <div class="col-sm-12 d-flex justify-content-between">
                      <div class="col-sm-6 d-flex align-items-center">
                        <div class="mr-1">
                          <i
                            class="fa fa-archive icon"
                            style="font-size: 35px; color: rgb(209, 140, 183)"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div>
                          <p
                            class="mb-1 color-white cursor-d"
                            style="color: #000000a1; font-size: 17px;"
                          >
                          Open Order Balance
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-6 align-text-end">
                        <span class="total-sty" style="color: #000000a1">{{
                          customerCounts.open ? customerCounts.open : 0
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">Latest Transaction</h4>
                <div class="table-responsive">
                  <table class="table table-centered table-nowrap mb-0">
                    <thead class="table-light">
                      <tr>
                        <th style="width: 20px">
                          <div class="form-check font-size-16">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="customCheck1"
                            />
                            <label class="form-check-label" for="customCheck1"
                              >&nbsp;</label
                            >
                          </div>
                        </th>
                        <th>Order ID</th>
                        <th>Customer Name</th>
                        <th>Order Type</th>
                        <th>Item Number</th>
                        <th>Ordered Date</th>
                        <th>View Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="form-check font-size-16">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="customCheck2"
                            />
                            <label class="form-check-label" for="customCheck2"
                              >&nbsp;</label
                            >
                          </div>
                        </td>
                        <td>
                          <a
                            
                            class="text-body fw-bold"
                            >#MB2540</a
                          >
                        </td>
                        <td>Karthik</td>
                        <td>Credit</td>
                        <td>123Kk</td>
                        <td>
                          03-11-2021
                        </td>

                        <td>
                          <button
                            type="button"
                            class="
                              btn btn-primary btn-sm btn-rounded
                              waves-effect waves-light
                            "
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div class="rightbar-overlay"></div>
