<div class="auth">
  <div class="account-pages pt-sm-5">
    <div class="container">
      <!-- <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <a href="index.html" class="mb-5 d-block auth-logo">
                <img
                  src="assets/images/logo-dark.png"
                  alt=""
                  height="22"
                  class="logo logo-dark"
                />
                <img
                  src="assets/images/logo-light.png"
                  alt=""
                  height="22"
                  class="logo logo-light"
                />
              </a>
            </div>
          </div>
        </div> -->
      <div class="row align-items-center justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card my-4">
            <div class="card-body p-4">
              <div class="text-center mt-2">
                <h5 class="text-primary">Registration</h5>
              </div>
              <div class="p-2 mt-4" *ngIf="regestrationForm">
                   <div class="form-group mb-3">
                    <label class="form-label" for="username">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="names"
                      [(ngModel)]="name"
                      placeholder="Enter Name"
                      />
                      <!-- <div *ngIf="f.username.invalid && f.username.touched">
                        <div
                        *ngIf="f.username.errors?.required"
                        class="text text-danger"
                        >
                        Mobile Number required
                      </div>
                    </div> -->
                  </div>
                  <div class="form-group mb-3">
                    <label class="form-label" for="username"
                    >Mobile Number</label
                    >
                    <input
                    class="form-control"
                    id="username"
                    type="number"
                    min="1"
                    max="99999"
                    [(ngModel)]="mobile"
                    name="mobile"
                    placeholder="Mobile Number"                    />
                    <!-- <div *ngIf="f.username.invalid && f.username.touched">
                        <div
                          *ngIf="f.username.errors?.required"
                          class="text text-danger"
                        >
                        Mobile Number required
                        </div>
                      </div> -->
                  </div>
                  <div class="form-group mb-3">
                    <label class="form-label" for="username">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      [(ngModel)]="email"
                      id="email"
                      placeholder="Enter Email"
                    />
                    <!-- <div *ngIf="f.username.invalid && f.username.touched">
                        <div
                          *ngIf="f.username.errors?.required"
                          class="text text-danger"
                        >
                        Mobile Number required
                        </div>
                      </div> -->
                  </div>
                  <div class="mt-4 text-end">
                    <button
                    (click)="regestration()"
                    [disabled]="spinner"
                      class="btn btn-primary w-sm waves-effect waves-light"
                      type="submit"
                      style="width: 100%"
                    >
                      Get OTP
                    </button>
                  </div>
</div>
                  <div class="mt-4 mb-4 text-center">
                    <p class="text-muted mb-2">Powered By</p>
                    <img
                      src="assets/images/logo.png"
                      alt=""
                      width="120px"
                      height="35px"
                      class="logo"
                    />
                    <!-- <div class="signin-other-title">
                        <h5 class="font-size-14 mb-3 title">Sign in with</h5>
                      </div>
    
                      <ul class="list-inline">
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="
                              social-list-item
                              bg-primary
                              text-white
                              border-primary
                            "
                          >
                            <i class="mdi mdi-facebook"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-info text-white border-info"
                          >
                            <i class="mdi mdi-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="
                              social-list-item
                              bg-danger
                              text-white
                              border-danger
                            "
                          >
                            <i class="mdi mdi-google"></i>
                          </a>
                        </li>
                      </ul> -->
                  </div>

                  <div class="mt-4 text-center">
                    <p class="mb-0">
                      Back to Login !
                      <a
                        class="fw-medium text-primary"
                        (click)="login()"
                        style="cursor: pointer"
                      >
                        Sign in
                      </a>
                    </p>
                  </div>
                 <div class="login-form" *ngIf="otpForm">
                  <div class="row">
                    <div class="input-field col s12">
                    
                      <h5 class="ml-4" style="text-align: center">Enter OTP</h5>
                    </div>
                  </div>
    
                  <div class="row margin">
                    <div class="input-field col s12">
                       
                      <input
                        id="name"
                        type="text"
                        [(ngModel)]="otpp"
                        name="otp"
                        class="form-control"
                        placeholder="OTP"
                      />
                    </div>
                  </div>
    
                  <div class="row">
                    <div class="input-field col s12">
                      <button
                        (click)="otp()"
                        [disabled]="spinner"
                        style="width: 100%"
                         class="btn btn-primary w-sm waves-effect waves-light mt-2 "
                       
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div class="login-form" *ngIf="passwordForm">
                  <div class="row">
                    <div class="input-field col s12">
                      <img
                        class="login-img"
                        src="assets/images/epcc/logo.png"
                        alt=""
                      />
                      <h5 class="ml-4" style="text-align: center">
                        Enter Password
                      </h5>
                    </div>
                  </div>
    
                  <div class="row margin">
                    <div class="input-field col s12">
                       <input
                        id="password"
                        class="form-control mt-2"
                        type="text"
                        [(ngModel)]="password"
                        name="name"
                        placeholder="Password"
                      />
                      <!-- <label for="password">Password</label> -->
                    </div>
                  </div>
                  <div class="row margin">
                    <div class="input-field col s12">
                       <input
                        id="password"
                        class="form-control mt-2"
                        type="text"
                        name="name"
                        placeholder="Re Type Password"
                      />
                      <!-- <label for="password">Password</label> -->
                    </div>
                  </div>
    
                  <div class="row">
                    <div class="input-field col s12">
                      <button
                        (click)="passwordSubmit()"
                        [disabled]="spinner"
                        style="width: 100%"
                        class="btn btn-primary w-sm waves-effect waves-light mt-2"
                      
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
             </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
</div>
